import './index.css';
require('../../../lib/jquery.min');
export default class playerNextComponent {
  constructor(clickHandle) {
    this.clickHandle = clickHandle;
    // eslint-disable-next-line no-undef
    this.$html = $('<div class="player-olympic-player-next">下一课时</div>');
  }

  createEl(el) {
    this.$html.click(this.clickHandle);
    // eslint-disable-next-line no-undef
    $(el).find('.prism-play-btn').after(this.$html);
  }
}
