// import rateHtml from './index.html';
import './index.scss';
import { parseDom } from '@/utils/index';

/**
 * 倍速播放组件
 */
export default class RateComponent {
  /**
   * @constructor 倍速播放组件构造函数
   */
  constructor() {
    this.html = parseDom('<div class="rate-components">\n' +
      '  <div class="current-rate">1.0x</div>\n' +
      '  <ul class="rate-list">\n' +
      '    <li data-rate="2.0">2.0x</li>\n' +
      '    <li data-rate="1.5">1.5x</li>\n' +
      '    <li data-rate="1.25">1.25x</li>\n' +
      '    <li data-rate="1.0" class="current">1.0x</li>\n' +
      '    <li data-rate="0.75">0.75x</li>\n' +
      '  </ul>\n' +
      '</div>');
  }

  createEl(el) {
    const eleControlbar = el.querySelector('.prism-controlbar');
    eleControlbar.appendChild(this.html);
  }

  ready(player, e) {
    const currentRateEle = this.html.querySelector('.current-rate');
    const rateListEle = this.html.querySelector('.rate-list');
    let timeId = null;

    // 隐藏设置里面的倍速播放
    const settingRate = document.querySelector('.prism-setting-item.prism-setting-speed');
    if (settingRate) {
      settingRate.classList.add('player-hidden');
    }

    currentRateEle.onclick = () => {
      rateListEle.style.display = 'block';
    };
    currentRateEle.onmouseleave = () => {
      timeId = setTimeout(() => {
        rateListEle.style.display = 'none';
      }, 100);
    };

    rateListEle.onmouseenter = () => {
      clearTimeout(timeId);
    };
    rateListEle.onmouseleave = () => {
      rateListEle.style.display = 'none';
    };

    rateListEle.onclick = ({ target }) => {
      const rate = target.dataset.rate;
      if (rate) {
        player.setSpeed(rate);
        if (target.className !== 'current') {
          const currentEle = rateListEle.querySelector('.current');
          if (currentEle) {
            currentEle.className = '';
          }
          target.className = 'current';
        }
        rateListEle.style.display = 'none';
        currentRateEle.innerText = rate + 'x';
      }
    };
  }
}
