import './index.scss';
import { parseDom, cookieSet } from '@/utils/index';
/**
 * 切换清晰度组件
 */
export default class QualityComponent {
  constructor(getQuality) {
    this.html = parseDom('<div class="quality-components">\n' +
    '<div class="current-quality" data-ref=""></div>\n' +
    '<ul class="quality-list">\n' +
    '</ul>\n' +
    '</div>');
    this.modalHtml = parseDom('<div class="quality-modal">\n' +
    '<span class="switchimg"></span> <span class="current-quality-tag"></span> , <span class="wait"></span>\n' +
    '</div>');
    this.hasCreated = false;
    this.definition = '';
    this.getQuality = getQuality;
  }

  createEl(el, player) {
    const lang = player._options && player._options.language;
    this.isEn = lang && lang === 'en-us';
    this.html.querySelector('.current-quality').innerText = this.isEn ? 'Resolution' : '清晰度';
    this.modalHtml.querySelector('.switchimg').innerText = this.isEn ? 'Switching to you for' : '正在为您切换到';
    this.modalHtml.querySelector('.wait').innerText = this.isEn ? 'Please wait...' : '请稍后...';
    const eleControlbar = el.querySelector('.prism-controlbar');
    eleControlbar.appendChild(this.html);
    el.appendChild(this.modalHtml);
  }

  setCurrentQuality(quality, def) {
    const currentQuality = this.html.querySelector('.current-quality');
    currentQuality.innerText = quality;
    currentQuality.dataset.def = def;
    this.definition = def;
    const qualityListEle = this.html.querySelector('.quality-list');
    const currentEle = qualityListEle.querySelector('.current');
    if (currentEle) {
      currentEle.className = '';
    }
    const liTarget = qualityListEle.querySelector(`li[data-def="${def}"]`);
    if (liTarget) {
      liTarget.className = 'current';
    }
  }

  created(player) {
    this._urls = player._urls;
    const currentQualityEle = this.html.querySelector('.current-quality');
    const qualityListEle = this.html.querySelector('.quality-list');
    const lisEle = this._urls.map(url => {
      return `<li data-def="${url.definition}">${url.desc}</li>`;
    });
    this.html.querySelector('.quality-list').innerHTML = lisEle.join('');
    console.log(this.definition);
    if (this.hasCreated === false && this.definition) {
      const liTarget = qualityListEle.querySelector(`li[data-def="${this.definition}"]`);
      liTarget.className = 'current';
    }
    this.hasCreated = true;
    let timeId = null;
    currentQualityEle.onclick = () => {
      const listVisible = qualityListEle.style.display !== 'none';
      if (listVisible) qualityListEle.style.display = 'none';
      if (!listVisible) qualityListEle.style.display = 'block';
    };
    currentQualityEle.onmouseleave = () => {
      if (timeId) clearTimeout(timeId);
      timeId = setTimeout(() => {
        qualityListEle.style.display = 'none';
      }, 150);
    };
    qualityListEle.onmouseenter = () => {
      clearTimeout(timeId);
    };
    qualityListEle.onmouseleave = () => {
      if (timeId) clearTimeout(timeId);
      timeId = setTimeout(() => {
        qualityListEle.style.display = 'none';
      }, 150);
    };
    qualityListEle.onclick = ({ target }) => {
      const definition = target.dataset.def;
      const desc = target.innerText;
      if (definition && target.className !== 'current') {
        const url = this._urls.find(url => url.definition === definition);
        if (url) {
          cookieSet('selectedStreamLevel', url.definition, 365);
          if (player._switchLevel && !player._options.isLive) {
            player._switchLevel(url.Url);
          }
          else {
            player._loadByUrlInner(url.Url, player.getCurrentTime(), true, true);
          }
          this.setCurrentQuality(url.desc, url.definition);
          this.modalHtml.style.display = 'block';
          this.modalHtml.querySelector('span.current-quality-tag').innerText = url.desc;
        }
      }
      this.getQuality(definition, desc);
    };
  }

  ready(player) {
    this.modalHtml.style.display = 'none';
    // 隐藏设置里面的倍速播放
    const settingEle = document.querySelector('.prism-setting-item.prism-setting-quality');
    if (settingEle) {
      settingEle.classList.add('player-hidden');
    }
  }
}
